/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Downshift from "downshift"
import FlexSearch from "flexsearch"
import { Link, Flex, Box } from "@theme-ui/components"
import L from "lodash"
import { IoIosSearch } from "react-icons/io"
import ReactGA from "react-ga"
import ga from "../ga"
console.log(`init ga`, ga)
ReactGA.initialize(ga)

const aboutText = item => item.about || L.get(item, `metadata.description`)
const searchIndexData = require(`../../content/data/search.json`)
const searchIndex = FlexSearch.create({
  doc: {
    id: `id`,
    field: [`text`, `extraText`, `about`],
  },
})
searchIndex.import(searchIndexData, { serialize: false })
export default ({ closeDrawer, sx }) => (
  <Downshift itemToString={item => (item ? item.value : ``)}>
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem,
      getRootProps,
      clearSelection,
    }) => {
      return (
        <div sx={{ position: `relative`, width: `100%`, ...sx }}>
          <Flex sx={{ position: `relative`, display: `flex` }} {...getRootProps({}, { suppressRefError: true })}>
            <input
              placeholder="Awesome Search..."
              sx={{
                border: `1px solid #e0e0e0`,
                padding: `5px`,
                paddingLeft: `30px`,
                flex: 1,
                borderRadius: `5px`,
                transition: `border-color .15s ease-in-out,box-shadow .15s ease-in-out`,
                ":focus": {
                  boxShadow: `0 0 0 0.2rem rgba(0,123,255,.25)`,
                  borderColor: `#80bdff`,
                  outline: 0,
                },
              }}
              {...{
                ...getInputProps(),
                onBlur: () => {
                  console.log(`search:`, inputValue)
                  ReactGA.pageview(`index/${inputValue}`)

                  clearSelection()
                },
              }}
              type="input"
            />
            <IoIosSearch sx={{ position: `absolute`, lineHeight: `2.2em`, height: `2.2em`, margin: `0 8px` }} />
          </Flex>
          {isOpen && inputValue && inputValue.length && (
            <ul
              {...getMenuProps()}
              sx={{
                "&&": {
                  top: `40px`,
                  position: `absolute`,
                  width: `100%`,
                  backgroundColor: `#fff`,
                  minHeight: `100px`,
                  maxHeight: `300px`,
                  overflowY: `auto`,
                  padding: 0,
                  margin: 0,
                  boxShadow: `0 2px 4px rgba(0,0,0,0.1)`,
                  zIndex: 999,
                },
              }}
            >
              {isOpen && inputValue && inputValue.length > 0
                ? searchIndex.search(inputValue).map((item, index) => (
                  <li
                    sx={{
                      margin: 0,
                      p: 0,
                    }}
                    {...getItemProps({
                      key: item.id,
                      index,
                      item,
                      style: {
                        backgroundColor: highlightedIndex === index ? `lightgray` : `white`,
                        fontWeight: selectedItem === item ? `bold` : `normal`,
                      },
                    })}
                  >
                    <Box sx={{ px: `10px` }}>
                      <Link variant="blank" onClick={closeDrawer} href={`#` + item.id}>
                          <div sx={{ fontWeight: 700 }}>{item.text}</div>
                        <div href={`#` + item.id} sx={{ color: `#a8a8a8` }}>
                            {aboutText(item)}
                        </div>
                        </Link>
                      </Box>
                  </li>
                ))
                : null}
            </ul>
          )}
        </div>
      )
    }}
    }
  </Downshift>
)
