/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import styled from 'styled-components'

import { Flex, Box, Text, Badge, Link } from '@theme-ui/components'
import L from 'lodash'
import { IoIosStar } from 'react-icons/io'
import Image from 'gatsby-image'

const Bio = ({ item, className }) => {
  const about = item.about || L.get(item, `metadata.description`)
  const image = L.get(
    item,
    `metadata.image`,
    `https://facebook.github.io/react-native/img/header_logo.svg`
  )
  return (
    <Flex className={className}>
      <Flex sx={{ flexDirection: `column`, mt: about ? 0 : `6px` }}>
        <Flex sx={{ flexDirection: [`column`, `row`] }}>
          <Text>
            <a id={item.id} />
            <Flex sx={{ flexDirection: `row` }}>
              <Link variant="primary" href={item.url}>
                {item.text}
              </Link>
              {item.stars && (
                <Text sx={{ ml: `10px`, fontWeight: 700 }}>
                  <IoIosStar sx={{ marginBottom: `-1px` }} /> {item.stars}
                </Text>
              )}
            </Flex>
          </Text>
        </Flex>
        {about && <Text sx={{ marginTop: [`5px`, 0] }}>{about}</Text>}
        <Flex sx={{ marginTop: `6px`, flexDirection: [`column`, `row`] }}>
          {image && (
            <Image
              backgroundColor="#f0f0f0"
              fixed={[
                {
                  width: `24px`,
                  height: `24px`,
                  minHeight: `24px`,
                  minWidth: `24px`,
                  src: image,
                },
              ]}
              sx={{ mt: about ? `2px` : 0, borderRadius: `50%` }}
            />
          )}
          <Link
            variant="slug"
            href={item.url}
            sx={{
              whiteSpace: `nowrap`,
              color: `pale`,
              marginLeft: [0, `8px`],
              marginRight: `20px`,
            }}
          >
            {item.slug}
          </Link>
          {item.tags && (
            <Box sx={{ marginTop: `-2px`, marginTop: [`5px`, 0] }}>
              {L.take(item.tags, 6).map(t => (
                <Badge variant="tag" sx={{ mr: `4px` }}>
                  {t}
                </Badge>
              ))}
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Bio
