/** @jsx jsx */
import React from 'react'

import { jsx } from 'theme-ui'
import './index.css'
import { Box, Flex } from '@theme-ui/components'
import { graphql } from 'gatsby'
import { slide as Drawer } from 'react-burger-menu'
import { IoIosMenu } from 'react-icons/io'
import ItemsIndex from '../components/items-index'
import index from '../../content/data/index.json'
import TOC from '../components/toc'
import Search from '../components/search'
import Logo from '../components/arn'

import Layout from '../components/layout'
import SEO from '../components/seo'

class BlogIndex extends React.Component {
  state = { visible: false }

  closeDrawer = () => {
    console.log(`close`)
    this.setState({
      visible: false,
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onClose = ({ isOpen }) => {
    if (isOpen == false) {
      this.setState({
        visible: false,
      })
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Awesome React Native components, news, tools, and learning material!" />

        <Drawer
          sx={{ display: [`block`, `none`] }}
          customBurgerIcon={false}
          isOpen={this.state.visible}
          onStateChange={this.onClose}
        >
          <TOC index={index} closeDrawer={this.closeDrawer} />
        </Drawer>
        <Flex
          sx={{
            position: `fixed`,
            height: `100px`,
            width: `100%`,
            pr: `10px`,
            backgroundColor: `white`,
            zIndex: 999,
            flexDirection: `row`,
            alignItems: `center`,
            boxShadow: `0 2px 4px rgba(0,0,0,0.1)`,
          }}
        >
          <IoIosMenu
            sx={{ position: `absolute`, left: 0, display: [`block`, `none`] }}
            onClick={this.showDrawer}
            size={50}
          />
          <Box
            sx={{ p: `10px`, mx: [`auto`, `10px`], width: [`140px`, `200px`] }}
          >
            <Logo />
          </Box>
          <Box sx={{ display: [`none`, `block`], width: `100%` }}>
            <Search sx={{ mx: `auto`, my: `auto`, width: `100%` }} />
          </Box>
        </Flex>

        <Flex sx={{ flexDirection: `row` }}>
          <Box
            sx={{
              position: `fixed`,
              top: 0,
              bottom: 0,
              overflowY: `scroll`,
              background: `#fff`,
              display: [`none`, `block`],
              width: `280px`,
              marginTop: `100px`,
              borderRight: `1px solid #f0f0f0`,
            }}
          >
            <TOC index={index} />
          </Box>

          <Flex
            sx={{
              paddingTop: `100px`,
              marginLeft: [0, `280px`],
              flexDirection: `column`,
            }}
          >
            <Flex>
              <Flex
                sx={{
                  maxWidth: `1440px`,
                  mx: `auto`,
                  width: `100%`,
                  flexDirection: `column`,
                  backgroundColor: `white`,
                }}
              >
                <Box sx={{ display: [`block`, `none`] }}>
                  <Search sx={{ mx: `auto`, width: `100%` }} />
                </Box>
                <ItemsIndex index={index} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/*
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
      */}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
