/** @jsx jsx */
import React, { memo } from 'react'
import { jsx } from 'theme-ui'

import styled from 'styled-components'
import { Link, Image, Flex, Box, Text, Badge } from '@theme-ui/components'
import Url from 'url-parse'
import L from 'lodash'
import { IoMdArrowUp } from 'react-icons/io'
import { groupBy } from 'ramda'
import ScrollToTop from 'react-scroll-up'
import ReactGA from 'react-ga'
import Item from './item'
import { slugForHeading } from './toc'
import ga from '../ga'

ReactGA.initialize(ga)

const titleForHeading = heading => heading.join(`: `)
const ItemsIndex = ({ index }) => {
  return (
    <Flex sx={{ flexDirection: `column` }}>
      <ScrollToTop showUnder={160}>
        <Box
          sx={{
            p: `10px`,
            borderRadius: `50%`,
            width: `70px`,
            height: `70px`,
            background: `#fff`,
            boxShadow: `0px 0px 10px rgba(0,0,0,0.15)`,
          }}
        >
          <IoMdArrowUp size={50} />
        </Box>
      </ScrollToTop>

      <Link variant="header">
        <header
          sx={{
            letterSpacing: `0.2px`,
            borderBottom: `1px solid #e5e5e5`,
            p: `8px 20px`,
            fontSize: [2, 4],
            color: `rgba(0, 0, 0, 0.8)`,
            fontWeight: 700,
          }}
        >
          <Text sx={{ mx: `auto`, maxWidth: `1440px` }}>Hardware</Text>
        </header>
      </Link>
      {[
        {
          link: `https://amzn.to/2tONOuL`,
          title: `Multi Device Organizer`,
          desc: `I use this to organize my mobile devices + charge them at once`,
          img: `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B009CFMO0S&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=arn07-20&language=en_US`,
        },
        {
          title: `Hercules Tuff Charging Station Organizer`,
          desc: `CHARGE EVERYTHING! with this`,
          link: `https://amzn.to/2Rny227`,
          img: `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07222V19V&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=arn07-20&language=en_US`,
        },
      ].map(item => (
        <Flex sx={{ borderRadius: `5px`, m: `10px` }}>
          <Box>
            <ReactGA.OutboundLink to={item.link} target="_blank">
              <img border="0" sx={{ width: 80, m: 0 }} src={item.img} />
            </ReactGA.OutboundLink>
          </Box>
          <Flex sx={{ flexDirection: `column`, padding: `20px` }}>
            <ReactGA.OutboundLink
              to={item.link}
              target="_blank"
              sx={{
                color: `#3a8bbb`,
                textDecoration: `none`,
                ':visited': {
                  textDecoration: `none`,
                  color: `#3a8bbb`,
                },
              }}
            >
              {item.title}
            </ReactGA.OutboundLink>
            <Text>{item.desc}</Text>
          </Flex>
        </Flex>
      ))}

      {Object.keys(index)
        .map(k => k.split(`$$`))
        .map(heading => (
          <>
            <a id={slugForHeading(heading)} />
            <Link
              variant="header"
              href={`#${slugForHeading(heading)}`}
              sx={{ paddingTop: `100px` }}
            >
              <header
                sx={{
                  letterSpacing: `0.2px`,
                  borderBottom: `1px solid #e5e5e5`,
                  p: `8px 20px`,
                  fontSize: [2, 4],
                  color: `rgba(0, 0, 0, 0.8)`,
                  fontWeight: 700,
                }}
              >
                <Text sx={{ mx: `auto`, maxWidth: `1440px` }}>
                  {titleForHeading(heading)}
                </Text>
              </header>
            </Link>
            <Box
              sx={{ maxWidth: `1440px`, width: `100%`, mx: `auto`, px: `20px` }}
            >
              {index[heading.join(`$$`)].map(item => (
                <Item key={item.url} item={item} sx={{ my: `30px` }} />
              ))}
            </Box>
          </>
        ))}
    </Flex>
  )
}

export default memo(ItemsIndex)
