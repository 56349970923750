/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { memo } from "react"
import { Flex, Box, Text, Link, Badge } from "@theme-ui/components"
import L from "lodash"
import slugify from "slugify"

const hardcoded = [`Articles`, `Components`, `Videos`]
const slugForHeading = heading => slugify(heading.join(`-`))
const renderHeading = (createHeadingPair, closeDrawer) => {
  return heading => (
    <Link onClick={closeDrawer} href={`#` + slugForHeading(heading)} variant="toc">
      <Flex sx={{ flexDirection: `row`, my: `15px` }}>
        <Text>{createHeadingPair(heading)[0]}</Text>
        <Badge variant="toc">{createHeadingPair(heading)[1]}</Badge>
      </Flex>
    </Link>
  )
}
const Section = ({ index, headings, kind, closeDrawer, split = 7 }) => (
  <Box>
    <Text sx={{ fontWeight: 700, my: `10px` }}>{kind}</Text>
    <Flex sx={{ flexDirection: `column` }}>
      {L.chain(headings)
        .filter(heading => heading[0] === kind)
        .chunk(split)
        .tap(_ => console.log(_))
        .map(chunked => (
          <Box>
            {chunked.map(renderHeading(heading => [heading[1], index[heading.join(`$$`)].length], closeDrawer))}
          </Box>
        ))
        .value()}
    </Flex>
  </Box>
)

const TOC = ({ id, index, closeDrawer, sx }) => {
  const headings = Object.keys(index).map(_ => _.split(`$$`))
  const withoutHardcoded = L.reject(headings, heading => L.includes(hardcoded, heading[0]))

  return (
    <Flex
      id={id}
      sx={{ flexDirection: `column`, maxWidth: `1440px`, width: `100%`, mx: `auto`, my: `10px`, px: `10px` }}
    >
      <Section closeDrawer={closeDrawer} headings={headings} index={index} kind="Components" />
      <Section closeDrawer={closeDrawer} headings={headings} index={index} kind="Articles" />
      <Box>
        <Text sx={{ fontWeight: 700, my: `10px` }}>Productivity &amp; Learning</Text>
        {withoutHardcoded.map(renderHeading(heading => [heading[0], index[heading.join(`$$`)].length], closeDrawer))}
      </Box>
      <Section closeDrawer={closeDrawer} headings={headings} index={index} kind="Videos" />
    </Flex>
  )
}

export { slugForHeading }

export default memo(TOC)
